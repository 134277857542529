<template>
  <div>
    <h2
      ref="advantagesHeading"
      v-motion-lexmea-slide-top
      class="cards-advantages px-3 text-4xl font-extrabold text-lexmea-blue-400"
    >
      {{ $t("info_pages.intro_page.advantages_section.title") }}
    </h2>
    <p
      v-motion-lexmea-slide-top
      class="relative z-10 mb-5 px-3 text-base text-lexmea-blue-500 md:text-xl"
    >
      {{ $t("info_pages.intro_page.advantages_section.description") }}
    </p>
    <div
      class="mx-auto flex w-fit flex-col justify-center gap-y-7 lg:flex-row lg:gap-20"
    >
      <Advantage>
        <template #icon>
          <img
            loading="lazy"
            class="aspect-square h-12 w-12 scale-[0.7] object-contain md:h-16 md:w-16"
            :src="verknupfeIcon"
            alt="Hirn"
            aria-hidden="true"
          />
        </template>
        <template #title
          >{{
            $t("info_pages.intro_page.advantages_section.first_feature.title")
          }}
        </template>
        <template #description
          >{{
            $t(
              "info_pages.intro_page.advantages_section.first_feature.description"
            )
          }}
        </template>
      </Advantage>

      <Advantage>
        <template #icon>
          <img
            loading="lazy"
            class="aspect-square h-12 w-12 scale-[0.9] object-contain p-2 md:h-16 md:w-16"
            :src="advantageIcon"
            alt="Lupe"
            aria-hidden="true"
          />
        </template>
        <template #title>
          {{
            $t("info_pages.intro_page.advantages_section.second_feature.title")
          }}
        </template>
        <template #description>
          <div>
            {{
              $t(
                "info_pages.intro_page.advantages_section.second_feature.description"
              )
            }}
          </div>
        </template>
      </Advantage>
      <Advantage renderSlotHTML>
        <template #icon>
          <img
            loading="lazy"
            class="aspect-square h-12 w-12 object-contain md:h-16 md:w-16"
            :src="advantageLeavesIcon"
            alt="Blätter"
            aria-hidden="true"
        /></template>
        <template #title
          >{{
            $t("info_pages.intro_page.advantages_section.third_feature.title")
          }}
        </template>
        <template #description>
          {{ studySustainablyText[0] }}
          <u @click="isCalculationPopUpActive = true">*</u>
          {{ studySustainablyText[1] }}
        </template>
      </Advantage>
    </div>
    <TheCalculationPopupModal v-model="isCalculationPopUpActive" />
  </div>
</template>

<script setup lang="ts">
import verknupfeIcon from "~/assets/landingpage/advantage/verknupfe.svg";
import advantageIcon from "~/assets/landingpage/advantage/advantage-search.png";
import advantageLeavesIcon from "~/assets/landingpage/advantage/advantage-leaves.png";
import { useI18n } from "vue-i18n";

const isCalculationPopUpActive = ref<boolean>(false);

const { t } = useI18n();
const studySustainablyText = computed(() => {
  return t(
    "info_pages.intro_page.advantages_section.third_feature.description"
  ).split("*");
});
const advantagesHeading = ref();
const { cardsImageCSSTopValue } = useProductCardsScrollParallax(
  -14,
  advantagesHeading,
  3
);
</script>
<style lang="scss" scoped>
.cards-advantages {
  z-index: 10;
  position: relative;

  &::before {
    content: url("~/assets/landingpage/bg-vectors/cards-vortile.svg");
    position: absolute;
    display: inline-block;
    top: v-bind(cardsImageCSSTopValue);
    left: -10px;
    z-index: -1;
  }
}
</style>
