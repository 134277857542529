<template>
  <div
    v-motion-lexmea-slide-top
    class="relative mx-auto -mt-[75px] w-full max-w-[1000px] md:-mt-[16rem] lg:-mt-[14.5rem]"
  >
    <div class="absolute flex h-full w-full justify-center gap-3 md:gap-10">
      <div class="lx-produkt-bg rounded-3xl bg-[#597A87]"></div>
      <div class="lx-produkt-bg rounded-3xl bg-[#597A87]"></div>
    </div>

    <div class="relative left-1/2 z-10 -translate-x-1/2 py-4 text-white">
      <img
        fetchpriority="high"
        class="mx-auto mb-4 w-[90%] rounded shadow-xl shadow-black/50 md:w-[80%] md:rounded-lg lg:rounded-xl"
        :srcset="`${cloudStorageUrl(
          'product-screenshot-400.webp'
        )} 480w, ${cloudStorageUrl('product-screenshot.webp')}`"
        :src="cloudStorageUrl('product-screenshot.webp')"
        alt="screenshot of the product"
      />

      <div
        class="mx-auto mt-[20px] flex w-[90%] items-center justify-center gap-4 md:w-[80%] lg:mt-10"
      >
        <!--  link and titles  -->
        <p class="w-full text-[27px] font-bold text-white lg:text-4xl">
          {{
            $t(
              "info_pages.intro_page.hero_section.preview_element.law_side.title"
            )
          }}
        </p>
        <img
          loading="lazy"
          class="max-w-[25%]"
          :src="productLinkIcon"
          alt="link"
          aria-hidden="true"
        />
        <p
          class="w-full text-right text-[27px] font-bold text-white lg:text-4xl"
        >
          {{
            $t(
              "info_pages.intro_page.hero_section.preview_element.knowledge_side.title"
            )
          }}
        </p>
      </div>

      <!-- lists -->
      <div
        class="mx-auto flex w-[90%] justify-between gap-3 hyphens-auto text-xs md:w-[80%] md:text-base lg:text-[1.2rem]"
      >
        <div class="w-fit max-w-[40%] py-4">
          <i18n-t
            keypath="info_pages.intro_page.hero_section.preview_element.law_side.description"
            tag="p"
            class="mb-2 text-white"
          >
            <template #emphasized>
              <b>{{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.law_side.emphasized"
                )
              }}</b>
            </template>
          </i18n-t>
          <ul class="list-[square]">
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.law_side.features.marking"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.law_side.features.linking"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.law_side.features.bookmarking"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.law_side.features.more"
                )
              }}
            </li>
          </ul>
        </div>

        <div class="w-fit max-w-[44%] py-4 md:max-w-[43%] lg:max-w-[45%]">
          <i18n-t
            keypath="info_pages.intro_page.hero_section.preview_element.knowledge_side.description"
            tag="p"
            class="mb-2 text-white"
          >
            <template #emphasized>
              <b>{{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.knowledge_side.emphasized"
                )
              }}</b>
            </template>
          </i18n-t>
          <ul class="list-[square] whitespace-nowrap">
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.knowledge_side.features.notes"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.knowledge_side.features.own_schemata"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.knowledge_side.features.lexmea_schemata"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "info_pages.intro_page.hero_section.preview_element.knowledge_side.features.more"
                )
              }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import productLinkIcon from "~/assets/landingpage/product/product-link.svg";
import { cloudStorageUrl } from "~/utils/cloudStorage";
</script>

<style lang="scss" scoped>
.lx-produkt-bg {
  @apply w-[47%] md:w-[40%];
}
</style>
