<template>
  <div>
    <div>
      <h2
        ref="funktionenHeading"
        v-motion-lexmea-slide-top
        class="cards-funktionen px-3 text-4xl font-extrabold text-lexmea-blue-400 md:text-[40px]"
      >
        {{ $t("info_pages.intro_page.features_section.title") }}
      </h2>
      <p
        v-motion-lexmea-slide-top
        class="relative z-10 mb-8 px-3 text-base text-lexmea-blue-500 md:text-xl"
      >
        {{ $t("info_pages.intro_page.features_section.description") }}
      </p>

      <FunctionVideo
        v-motion-lexmea-slide-left
        class="mb-32"
        videoName="%2323-02-23%20Markieren%20720p_compressed"
        posterFileName="funktionen-video-poster-1.jpg"
        :title="
          $t('info_pages.intro_page.features_section.first_feature.title')
        "
        :description="
          $t('info_pages.intro_page.features_section.first_feature.description')
        "
      />

      <FunctionVideo
        v-motion-lexmea-slide-right
        class="mb-32"
        :reverse="true"
        videoName="%2323-02-23%20Notizen%20720p%20ohne%20Zoom_compressed"
        posterFileName="funktionen-video-poster-2.jpg"
        :title="
          $t('info_pages.intro_page.features_section.second_feature.title')
        "
        :description="
          $t(
            'info_pages.intro_page.features_section.second_feature.description'
          )
        "
      />

      <FunctionVideo
        v-motion-lexmea-slide-left
        class="mb-32"
        videoName="%2323-02-23%20Schemata%20720p_compressed"
        posterFileName="funktionen-video-poster-3.jpg"
        :title="
          $t('info_pages.intro_page.features_section.third_feature.title')
        "
        :description="
          $t('info_pages.intro_page.features_section.third_feature.description')
        "
      />
    </div>
    <div class="mx-auto w-full">
      <TrialButton />
    </div>
  </div>
</template>
<script setup lang="ts">
const funktionenHeading = ref<HTMLElement>();

const { cardsImageCSSTopValue } = useProductCardsScrollParallax(
  -35,
  funktionenHeading,
  1.1
);
</script>
<style lang="scss" scoped>
.cards-funktionen {
  z-index: 10;
  position: relative;
  padding-right: 47px;

  &::before {
    content: url("~/assets/landingpage/bg-vectors/cards-funktionen.svg");
    position: absolute;
    display: inline-block;
    top: v-bind(cardsImageCSSTopValue);
    left: -8px;
    z-index: -1;
  }
}
</style>
