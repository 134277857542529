<template>
  <ActiveParagraphLink
    v-ripple="{
      color: !white ? 'white' : 'blue',
    }"
    class="mx-auto flex w-fit items-center justify-center rounded-xl px-5 py-2 text-sm font-black shadow transition duration-500 ease-in-out hover:scale-110 focus:outline-none focus:ring-2 focus:ring-lexmea-blue-400 focus:ring-opacity-50 md:px-6 md:py-3 md:text-lg"
    :class="{
      'bg-lexmea-gray-100 text-lexmea-blue-400': white,
      'bg-lexmea-blue-400 text-lexmea-gray-100': !white,
    }"
    @click="setLocalStorageRotateButtonFlagTrue()"
  >
    <WorkspaceIcon filled class="mr-2 h-4 w-4" :white="!white" />
    {{ $t("info_pages.intro_page.hero_section.use_for_free") }}
  </ActiveParagraphLink>
</template>

<script lang="ts" setup>
import { useLocalStorage } from "@vueuse/core";

withDefaults(
  defineProps<{
    white?: boolean;
  }>(),
  {
    white: false,
  }
);

const workspaceIconAnimationState = useLocalStorage(
  "workspace-icon-animation",
  "",
  {
    initOnMounted: true,
  }
);

const { vRipple } = useVRipple();

function setLocalStorageRotateButtonFlagTrue() {
  workspaceIconAnimationState.value = "1";
}
</script>
