export default function useProductCardsScrollParallax(
  defaultCardTopValue: number,
  targetElement: Ref<HTMLElement | undefined>,
  multiplier: number = 1
) {
  const cardsImageCSSTopValue = ref(`${defaultCardTopValue}px`);
  const mainContainer = ref();
  const viewportTop = ref();
  const viewportHeight = ref();

  tryOnMounted(() => {
    mainContainer.value = document.querySelector("main");
    viewportTop.value = document.documentElement.scrollTop;
    viewportHeight.value = document.documentElement.clientHeight;
  });

  const { y } = useScroll(mainContainer);

  const isVisible = ref(false);
  const absDefaultCardTopValue = useAbs(defaultCardTopValue);

  useIntersectionObserver(targetElement, ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;
  });

  watch(y, () => {
    if (isVisible.value && targetElement.value) {
      const elementRect = targetElement.value.getBoundingClientRect();

      let percentage =
        ((elementRect.top - viewportTop.value) / viewportHeight.value) * 100;

      percentage = Math.round(Math.max(0, Math.min(100, percentage)));

      const percentageToNumber =
        (absDefaultCardTopValue.value / 100) * percentage -
        absDefaultCardTopValue.value;

      cardsImageCSSTopValue.value = `${percentageToNumber * multiplier}px`;
    }
  });

  return { cardsImageCSSTopValue };
}
