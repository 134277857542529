<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="ring-on-hover flex flex-1 cursor-pointer flex-row items-center gap-7 rounded-3xl px-3 py-6 transition duration-300 hover:bg-[#f9f8f8] hover:shadow-xl lg:max-w-sm lg:flex-col"
  >
    <div class="ring-target shrink-0">
      <slot name="icon"></slot>
    </div>
    <div>
      <h3
        class="text-xl font-extrabold text-lexmea-blue-200 md:mb-1 md:text-2xl lg:text-center"
      >
        <slot name="title"></slot>
      </h3>
      <p
        class="text-sm font-normal text-lexmea-blue-500 md:text-xl lg:text-center"
      >
        <slot name="description"></slot>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="scss" scoped>
.ring-on-hover:hover {
  & > .ring-target {
    @apply rounded-full ring-[5px] ring-lexmea-blue-400 transition duration-300 ease-in-out;
  }
}
</style>
