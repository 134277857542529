<template>
  <div class="relative flex flex-col items-center">
    <span
      class="absolute block h-full w-full skew-y-2 bg-lexmea-gray-100"
    ></span>
    <div
      class="relative mb-12 mt-[-48px] flex flex-col gap-y-24 px-4 md:mb-28 md:mt-[78px] md:gap-y-40 md:px-7 lg:mt-[16px] lg:max-w-[90%]"
    >
      <slot></slot>
    </div>
  </div>
</template>
